
  import HeaderLayout from "@/components/layouts/Header.vue"
  import FooterLayout from "@/components/layouts/Footer.vue"
  import SingleBlock from "@/components/blocks/Single.vue"
  import Sitemap from "@/components/sitemap/Sitemap.vue";

  export default {
    name: "SitemapView",
    components: {
      HeaderLayout,
      FooterLayout,
      SingleBlock,
      Sitemap,
    },
  }
