import { Router, RouteRecordRaw, createRouter, createWebHistory } from "vue-router";
import Index from "@/views/Index.vue";
import Werkzaamheden from "@/views/werkzaamheden/Werkzaamheden.vue";
import Vervaardiging from "@/views/werkzaamheden/vervaardiging/Vervaardiging.vue";
import Revisie from "@/views/werkzaamheden/revisie/Revisie.vue";
import Onderhoud from "@/views/werkzaamheden/onderhoud/Onderhoud.vue";
import Tarieven from "@/views/tarieven/Tarieven.vue";
import Contact from "@/views/contact/Contact.vue";
import AlgemeneVoorwaarden from "@/views/algemeneVoorwaarden/AlgemeneVoorwaarden.vue";
import Sitemap from "@/views/sitemap/Sitemap.vue";
import PageNotFound from "@/views/errors/PageNotFound.vue";

const routes: RouteRecordRaw[] = [
    {
        path: "/",
        name: "MaatComputers",
        component: Index,
        meta: {
            title: "MaatComputers",
        },
    },
    {
        path: "/werkzaamheden",
        name: "Werkzaamheden",
        component: Werkzaamheden,
        meta: {
            title: "Werkzaamheden",
        },
    },
    {
        path: "/werkzaamheden/vervaardiging",
        name: "Vervaardiging",
        component: Vervaardiging,
        meta: {
            title: "Vervaardiging",
        },
    },
    {
        path: "/werkzaamheden/revisie",
        name: "Revisie",
        component: Revisie,
        meta: {
            title: "Revisie",
        },
    },
    {
        path: "/werkzaamheden/onderhoud",
        name: "Onderhoud",
        component: Onderhoud,
        meta: {
            title: "Onderhoud",
        },
    },
    {
        path: "/tarieven",
        name: "Tarieven",
        component: Tarieven,
        meta: {
            title: "Tarieven",
        },
    },
    {
        path: "/contact",
        name: "Contact",
        component: Contact,
        meta: {
            title: "Contact",
        },
    },
    {
        path: "/algemene_voorwaarden",
        name: "AlgemeneVoorwaarden",
        component: AlgemeneVoorwaarden,
        meta: {
            title: "Algemene voorwaarden",
            hideInNavigator: true,
        },
    },
    {
        path: "/sitemap",
        name: "Sitemap",
        component: Sitemap,
        meta: {
            title: "Sitemap",
            hideInNavigator: true,
            hideInSitemap: true,
        },
    },
    {
        path: "/:catchAll(.*)",
        name: "PageNotFound",
        component: PageNotFound,
        meta: {
            title: "Pagina niet gevonden",
            hideInNavigator: true,
            hideInSitemap: true,
        },
    },
];

const router: Router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.afterEach((to) => {
    if (to.meta.title == null) {
        document.title = "MaatComputers | Computers op maat";
    } else {
        let appendix: string;

        if (to.fullPath == "/") {
            appendix = " | Computers op maat";
        } else {
            appendix = " | MaatComputers";
        }

        document.title = to.meta.title as string + appendix;
    }
});

export default router;
