
import ImageLoader from "@/assets/scripts/ImageLoader";
import NavigatorList from "@/components/navigator/components/List.vue";
import LogoButton from "@/components/buttons/Logo.vue";
import MenuButton from "@/components/buttons/Menu.vue";

export default {
  name: "Navigator",
  data() {
    return {
      displayMenu: false as boolean,
      minimumBarWidth: 0 as number,
    };
  },
  components: {
    NavigatorList,
    LogoButton,
    MenuButton,
  },
  methods: {
    setBackground(): void {
      (this as any).$refs.navigator.style.backgroundImage
        = "url('" + ImageLoader.loadImageSrc("assets/images/backgrounds/maatcomputers.jpg") + "')";
    },
    switchDisplayMenu(): void {
      (this as any).displayMenu = !(this as any).displayMenu;
    },
    setMinimumBarWidth(): void {
      const minimumGap = 200;
      const containerMarginPercentage: number =
        2 *
        parseInt(
          getComputedStyle(document.body).getPropertyValue("--window-margin-percentage")
        );
      const marginRate: number =
        (100 + containerMarginPercentage) / 100;

      (this as any).minimumBarWidth = Math.round(
        marginRate *
          (minimumGap +
            (this as any).$refs.navigatorLogo.getBoundingClientRect().width +
            (this as any).$refs.navigatorBar.getBoundingClientRect().width)
      );
    },
    adjustNavigatorType(): void {
      const containerWidth: number = (this as any).$refs.container.getBoundingClientRect()
        .width;

      if (containerWidth < (this as any).minimumBarWidth) {
        // Show menu navigator.
        if ((this as any).$refs.navigatorBar.style.display == "inline-block") {
          (this as any).$refs.navigatorBar.style.display = "none";
        }
        if ((this as any).$refs.navigatorSwitch.style.display != "inline-block") {
          (this as any).$refs.navigatorSwitch.style.display = "inline-block";
        }
      } else {
        // Show bar navigator and hide menu links.
        if ((this as any).displayMenu) {
          (this as any).displayMenu = false;
        }
        if ((this as any).$refs.navigatorBar.style.display != "inline-block") {
          (this as any).$refs.navigatorBar.style.display = "inline-block";
        }
        if ((this as any).$refs.navigatorSwitch.style.display == "inline-block") {
          (this as any).$refs.navigatorSwitch.style.display = "none";
        }
      }
    },
    adjustNavigatorPlaceholderHeight(): void {
      if ((this as any).$refs.navigator.offsetHeight !=
          (this as any).$refs.navigatorPlaceholder.offsetHeight) {
        (this as any).$refs.navigatorPlaceholder.style.height =
          (this as any).$refs.navigator.offsetHeight + "px";
      }
    },
  },
  mounted() {
    (this as any).$refs.navigatorBar.style.display = "inline-block";
    (this as any).$refs.navigatorSwitch.style.display = "inline-block";

    (this as any).setBackground();
    (this as any).setMinimumBarWidth();
    (this as any).adjustNavigatorType();
    (this as any).adjustNavigatorPlaceholderHeight();

    window.addEventListener("resize", (this as any).adjustNavigatorType);
    window.addEventListener(
      "resize",
      (this as any).adjustNavigatorPlaceholderHeight
    );
  },
  beforeUnmount() {
    window.removeEventListener("resize", (this as any).adjustNavigatorType);
    window.removeEventListener(
      "resize",
      (this as any).adjustNavigatorPlaceholderHeight
    );
  },
};
