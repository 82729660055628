import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-581f5b64"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "navigator",
  ref: "navigator"
}
const _hoisted_2 = {
  class: "container",
  ref: "container"
}
const _hoisted_3 = {
  class: "navigator-logo",
  ref: "navigatorLogo"
}
const _hoisted_4 = {
  class: "navigator-bar",
  ref: "navigatorBar"
}
const _hoisted_5 = {
  class: "navigator-switch",
  ref: "navigatorSwitch"
}
const _hoisted_6 = {
  class: "navigator-placeholder",
  ref: "navigatorPlaceholder"
}
const _hoisted_7 = {
  key: 0,
  class: "navigator-menu"
}
const _hoisted_8 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logo_button = _resolveComponent("logo-button")!
  const _component_navigator_list = _resolveComponent("navigator-list")!
  const _component_menu_button = _resolveComponent("menu-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_logo_button)
        ], 512),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_navigator_list)
        ], 512),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_menu_button, { onclick: $options.switchDisplayMenu }, null, 8, ["onclick"])
        ], 512)
      ], 512)
    ], 512),
    _createElementVNode("section", _hoisted_6, null, 512),
    ($data.displayMenu)
      ? (_openBlock(), _createElementBlock("section", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_navigator_list, { isMenu: true })
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}